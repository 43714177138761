const wrapper = document.querySelector('.header-wrapper');
const body = document.querySelector('body');
const main = document.querySelector('main');
const hamburger = document.querySelector('.mobile-hamburger');
const hamburgerClose = document.querySelector('.mobile-hamburger-close');
const mobileNavItems = document.getElementById("nav-links-mobile-container");

if (isOnMobileDevice()) {
  hamburger.addEventListener('touchend', showHideMobileNavigation);
  hamburgerClose.addEventListener('touchend', showHideMobileNavigation);
} else {
  hamburger.addEventListener('click', showHideMobileNavigation);
  hamburgerClose.addEventListener('click', showHideMobileNavigation);
}

function tapOutsideHeader(evt) {
  if (!wrapper.contains(evt.target)) {
    closeHeader();
  }
}

function closeHeader() {
  body.classList.remove('has-overlay');
  main?.classList.remove('has-overlay');
  mobileNavItems.style.display = "none";
  hamburger.style.display = "flex";
  hamburgerClose.style.display = "none";

  if (isOnMobileDevice()) {
    window.removeEventListener('touchend', tapOutsideHeader);
  } else {
    window.removeEventListener('click', tapOutsideHeader);
  }
}

function openHeader() {
  body.classList.add('has-overlay');
  main?.classList.add('has-overlay');
  mobileNavItems.style.display = "flex";
  hamburger.style.display = "none";
  hamburgerClose.style.display = "flex";

  if (isOnMobileDevice()) {
    window.addEventListener('touchend', tapOutsideHeader);
  } else {
    window.addEventListener('click', tapOutsideHeader);
  }
}

function showHideMobileNavigation() {
  if (mobileNavItems.style.display === "flex") {
    closeHeader();
  } else {
    openHeader();
  }
}

window.addEventListener('resize', function () {
  if (window.innerWidth <= 1010) {
    hamburger.style.display = 'flex';
  } else {
    hamburger.style.display = 'none';
  }

  hamburgerClose.style.display = 'none';
  mobileNavItems.style.display = 'none';
  body?.classList.remove('has-overlay');
  main?.classList.remove('has-overlay');
});
